import luna from './luna.jpg';
import paw from './black-paw.png';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="home-social-links">
          <a href='https://www.instagram.com/lunathedoo'><FontAwesomeIcon icon={faInstagram} /></a>
          <a href='https://www.tiktok.com/@lunathedoo'><FontAwesomeIcon icon={faTiktok} /></a>
        </div>
        <h1 class="home-title">Luna the Goldendoodle</h1>
        <img src={paw} className='home-paw-pic' alt='paw'/>
        <img src={paw} className='home-smaller-paw-pic' alt='paw'/>
        <img src={luna} className='home-luna-pic' alt='luna' />
        <div class="home-main-text">
          <p>
            Found our Luna?
          </p>
          <p>
            Please contact us!
          </p>
        </div>
        <div class="home-contact-info">
          <p><a href='tel:8134097570'>(813) 409-7570</a></p>
          <p><a href='mailto:lunathedoo@gmail.com'>lunathedoo@gmail.com</a></p>
        </div>
      </header>
    </div>
  );
}

export default App;
